(function() {
	/**
	 * @exports DKA.controllers.home
	 * @requires HBS
	 */
	let module = {};

	module.init = () => {
		console.log('Home.js initialized.')
	};

	module.examplePage = () => {
		
	};

	HBS.namespace('DKA.controllers.home', module);
}());